import axios from "axios";
import Cookie from "js-cookie";

const createOrg = async (data) => {
  try {
    let res = await axios.post(
      process.env.NEXT_PUBLIC_BASE_URL + "organizations/",
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
const updateOrg = async (data) => {
  try {
    let res = await axios.patch(
      process.env.NEXT_PUBLIC_BASE_URL + "organizations/",
      data,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200 || res.status === 201) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};
async function deleteOrg(id) {
  try {
    let res = await axios.delete(
      process.env.NEXT_PUBLIC_BASE_URL + "organizations/" + id,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 204) {
      console.log({ resData: res });
    } else {
      throw new Error("not Authenticated");
    }
  } catch (e) {
    throw e;
  }
}

async function sendMessage(data, id) {
  try {
    let res = await axios.patch(
      process.env.NEXT_PUBLIC_BASE_URL + "organizations/" + id + "/",

      data,
      {
        headers: {
          Authorization: `Bearer ${Cookie.get("token")}`,
        },
      }
    );
    if (res.status === 200) {
      return res;
    } else {
    }
  } catch (e) {
    console.log({ errData: e });
  }
}

export const orgsService = {
  createOrg,
  deleteOrg,
  updateOrg,
  sendMessage,
};
