import {
  CLEAR_TRAPS_ON_MAP,
  READ_ONE_TRAPS_ON_MAP,
  SET_TRAPS_ON_MAP_DATA,
  READ_TRAPS_ON_MAPS,
  START_TRAPS_RELOAD,
  FINISHED_TRAPS_RELOAD,
  TRAPS_ON_MAP_ERROR,
  READ_TRAPS,
  READ_TRAPS_ACTIVITIES,
  READ_TRAPS_CHARTS,
} from "../../types/tree/pest-disease";
const initialState = {
  pest_disease_fields: [], // read pivot pest_disease_fields
  pest_disease_field: {}, // for one pivot pest_disease_field
  chartData: [], // read chart
  trapsActivities: [],
  traps: [],
  loading: false,
  readable: false,
  errors: [],
};
export default function pest_disease_fieldsReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case SET_TRAPS_ON_MAP_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_TRAPS: // reading pivot pest_disease_fields
      return {
        ...state,
        traps: payload.results,
      };
    case READ_TRAPS_ACTIVITIES: // reading pivot pest_disease_fields
      return {
        ...state,
        trapsActivities: payload,
      };
    case READ_TRAPS_CHARTS: // reading pivot pest_disease_fields
      return {
        ...state,
        chartData: payload,
      };
    case READ_TRAPS_ON_MAPS: // reading pivot pest_disease_fields
      return {
        ...state,
        pest_disease_fields: payload,
        readable: true,
      };
    case READ_ONE_TRAPS_ON_MAP: // read one pivot pest_disease_field
      return {
        ...state,
        pest_disease_field: payload,
      };
    case CLEAR_TRAPS_ON_MAP:
      return {
        ...state,
        pest_disease_field: {},
      };

    case START_TRAPS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_TRAPS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case TRAPS_ON_MAP_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };

    default:
      return state;
  }
}
