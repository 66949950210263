import { useMemo } from "react";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import reducers from "@/redux/reducers";
let store;
const isClient = typeof window !== "undefined";
let initStore = null;
if (isClient) {
  const { persistStore, persistReducer } = require("redux-persist");
  const storage = require("redux-persist/lib/storage").default;
  const persistConfig = {
    key: "root",
    whitelist: [""],
    storage,
  };
  initStore = (initialState) => {
    store = createStore(
      persistReducer(persistConfig, reducers),
      initialState,
      composeWithDevTools(applyMiddleware(thunkMiddleware))
    );
    store.__PERSISTOR = persistStore(store);
    return store;
  };
} else {
  initStore = (initialState) => {
    return (store = createStore(
      reducers,
      initialState,
      composeWithDevTools(applyMiddleware(thunkMiddleware))
    ));
  };
}
export const initializeStore = (preloadedState) => {
  let _store = store ?? initStore(preloadedState);

  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    store = undefined;
  }
  if (typeof window === "undefined") return _store;
  if (!store) store = _store;

  return _store;
};
export default function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  return store;
}
