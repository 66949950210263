import { useState, useEffect } from "react";
import Layout from "layout/index";
import "../locales/i18n";
import "../styles/global.css";
import "styles/map-control.css";
import "styles/multiRangeSlider.css";
import { Provider } from "react-redux";
import useStore from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { authServices } from "services/auth.services";
import axios from "axios";
import Cookie from "js-cookie";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import Head from "next/head";
import "react-leaflet-markercluster/dist/styles.min.css";
import Loader from "@/components/common/loaderV2";
import Alerts from "layout/alerts.component";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_URL;

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [isLogedin, setIsLogedin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (Cookie.get("lang")) {
      i18n.changeLanguage(Cookie.get("lang"));
    }
  }, [Cookie.get("lang")]);

  const grantedPaths = [
    "/contact-us",
    "/apppolicy",
    "/reports/rpw",
    "/fs1pivot12report",
    "/toshkalakes",
    "/Agri-Tech",
    "/about",
    "/test",
    "/Agri-Tech/blogs",
    "/Agri-Tech/blogs/CropKCestimation",
    "/Agri-Tech/packages",
    "/Agri-Tech/FAQ",
    "/Agri-Tech/blogs/howMuchIrrigateToday",
    "/learning-zone/alfalfairrigation",
    "/learning-zone/alfalfaservice",
    "/learning-zone/alfalfafertilization",
    "/learning-zone/alfalfainsectdiseases",
    "/learning-zone/alfalfa",
    "/learning-zone/alfalfaplantation",
    "/Agri-Tech/blogs/digitizeFarmProduction",
    "/Agri-Tech/blogs/VariableRateTechniques",
    "/Agri-Trade",
    "/Agri-Processing",
  ];

  const publicPaths = [
    "/auth/login",
    "/auth/forget-password",
    "/auth/change-password",
    "/about",
    "/Agri-Tech",
    "/toshkalakes",
    "/fs1pivot12report",
    "/test",
    "/Agri-Tech/blogs",
    "/apppolicy",
    "/Agri-Tech/blogs/CropKCestimation",
    "/Agri-Tech/packages",
    "/Agri-Tech/FAQ",
    "/Agri-Tech/blogs/howMuchIrrigateToday",
    "/Agri-Tech/blogs/digitizeFarmProduction",
    "/Agri-Tech/blogs/VariableRateTechniques",
    "/Agri-Trade",
    "/Agri-Processing",
  ];

  const url = router.asPath;
  const path = url.split("?")[0];

  // Function to check if the path is a dynamic Agri-Trade path
  const isDynamicAgriTradePath = (path) => {
    const dynamicAgriTradeRegex = /^\/Agri-Trade\/.+/;
    return dynamicAgriTradeRegex.test(path);
  };

  useEffect(() => {
    const isAuthenticated = authServices.userValue && Cookie.get("token");

    if (isAuthenticated) {
      if (publicPaths.includes(path) || isDynamicAgriTradePath(path)) {
        if (!grantedPaths.includes(path) && !isDynamicAgriTradePath(path)) {
          router.push({
            pathname: "/farms",
          });
          return;
        }
      } else {
        setIsLogedin(true);
        return;
      }
    } else {
      if (!publicPaths.includes(path) && !isDynamicAgriTradePath(path)) {
        setIsLoading(true);
        router.push({
          pathname: "/about",
        });
        return;
      }
      setIsLogedin(false);
      setIsLoading(false);
    }
  }, [
    authServices.userValue,
    Cookie.get("token"),
    path,
    publicPaths,
    grantedPaths,
    router,
  ]);

  const TOKEN_REFRESH_INTERVAL = 1000 * 60 * 1.5;

  useEffect(() => {
    if (!authServices.checkToken() && Cookie.get("token")) {
      authServices.logout();
    }
  }, [Cookie.get("token")]);

  useEffect(() => {
    let interval;

    if (isLogedin && Cookie.get("token")) {
      interval = setInterval(() => {
        authServices.revalidateToken();
      }, TOKEN_REFRESH_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [isLogedin, Cookie.get("token")]);

  const store = useStore(pageProps.initialReduxState);

  if (isLoading) {
    return (
      <>
        <Head>
          <title>Platfarm</title>
          <link rel="icon" href="/favicon/favicon.ico" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            content="Agri-business company PLATFARM, Headquartered in UAE and Egypt, creates digital twin to digitize crop management for Middle East farms. To enable the next generation of farms in the MENA region, Platfarm harnesses the power of ground and remote sensing data along with farm daily operations logs"
          />
          <meta
            name="keywords"
            content="Digital twin for crop management, Agri-business company PLATFARM, Crop digitization in the Middle East, Farm management technology in MENA, Ground and remote sensing data for agriculture, Advanced farm operations logs, Next-generation farms in the Middle East, Agtech solutions for MENA farms, Precision farming in the Middle East, Digitizing agriculture in the MENA region"
          />
        </Head>
        <div className="w-full h-[100vh]">
          <div className="flex items-center justify-center w-full h-full">
            <Loader />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Head>
        <title>Platfarm</title>
        <link rel="icon" href="/favicon/favicon.ico" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta
          name="keywords"
          content="Digital twin for crop management, Agri-business company PLATFARM, Crop digitization in the Middle East, Farm management technology in MENA, Ground and remote sensing data for agriculture, Advanced farm operations logs, Next-generation farms in the Middle East, Agtech solutions for MENA farms, Precision farming in the Middle East, Digitizing agriculture in the MENA region"
        />
        <meta
          name="description"
          content="Agri-business company PLATFARM, Headquartered in UAE and Egypt, creates digital twin to digitize crop management for Middle East farms. To enable the next generation of farms in the MENA region, Platfarm harnesses the power of ground and remote sensing data along with farm daily operations logs"
        />
      </Head>
      <div>
        <Provider store={store}>
          <PersistGate persistor={store.__PERSISTOR} loading={null}>
            {isLogedin && !grantedPaths.includes(path) ? (
              <Layout>
                <Component {...pageProps} />
              </Layout>
            ) : (
              <div
                style={{
                  fontFamily:
                    i18n.language === "en" ? "Baloo-regular" : "Almarai",
                }}
                dir={i18n.language === "en" ? "ltr" : "rtl"}
              >
                <Alerts />
                <Component {...pageProps} />
              </div>
            )}
          </PersistGate>
        </Provider>
      </div>
    </>
  );
}

export default MyApp;
