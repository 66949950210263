import {
  SET_SENSING_DATA,
  READ_FARM_IMAGES_DATES,
  READ_FARM_PIVOTS_DETAILS,
  REFRESH_MAP,
  START_SENSINGS_RELOAD,
  FINISHED_SENSINGS_RELOAD,
  SENSING_ERROR,
  CLEAR_SENSING,
  READ_SENSING_CHARTS,
  READ_CLUSTER_IMAGES_DATES,
  READ_CROP_FIELD_IMAGES_DATES,
  READ_ALL_FARM_CLUSTERS,
} from "../../types/pivot/sensing";
import _ from "lodash";
const initialState = {
  index: "NDVI",
  time: 2,
  farm_dates: [],
  origin_farm_dates: [],
  farm_crops_details: [],
  min: "",
  max: "",
  refreshMap: true,
  sensingCharts: [],
  errors: [],
  farmClusters: [],
};
export default function sensingReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SENSING_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_FARM_IMAGES_DATES:
      return {
        ...state,
        min: new Date(
          _.min?.(payload?.map?.((date) => new Date(date.image_date)))
        )
          ?.toISOString()
          .split("T")[0],
        max: new Date(
          _.max(payload?.map?.((date) => new Date(date.image_date)))
        )
          ?.toISOString()
          .split("T")[0],
        farm_dates: payload.map((p, i) => {
          return { ...p, active: i + 1 === payload?.length ? true : false };
        }),
        origin_farm_dates: payload.map((p, i) => {
          return { ...p, active: i + 1 === payload?.length ? true : false };
        }),
      };
    case READ_CROP_FIELD_IMAGES_DATES:
      return {
        ...state,
        min: new Date(
          _.min?.(payload?.map?.((date) => new Date(date.image_date)))
        )
          ?.toISOString()
          .split("T")[0],
        max: new Date(
          _.max(payload?.map?.((date) => new Date(date.image_date)))
        )
          ?.toISOString()
          .split("T")[0],
        farm_dates: payload.map((p, i) => {
          return { ...p, active: i + 1 === payload?.length ? true : false };
        }),
        origin_farm_dates: payload.map((p, i) => {
          return { ...p, active: i + 1 === payload?.length ? true : false };
        }),
      };
    case READ_CLUSTER_IMAGES_DATES:
      return {
        ...state,
        min: new Date(
          _?.min?.(payload?.map?.((date) => new Date(date.image_date)))
        )
          ?.toISOString()
          .split("T")[0],
        max: new Date(
          _.max(payload?.map?.((date) => new Date(date.image_date)))
        )
          ?.toISOString()
          .split("T")[0],
        farm_dates: payload.map((p, i) => {
          return { ...p, active: i + 1 === payload?.length ? true : false };
        }),
        origin_farm_dates: payload.map((p, i) => {
          return { ...p, active: i + 1 === payload?.length ? true : false };
        }),
      };
    case READ_FARM_PIVOTS_DETAILS:
      return {
        ...state,
        farm_crops_details: payload,
      };

    case REFRESH_MAP:
      return {
        ...state,
        refreshMap: payload,
      };
    case READ_SENSING_CHARTS:
      return {
        ...state,
        sensingCharts: payload,
      };
    case CLEAR_SENSING:
      return {
        ...state,
        geo_field: {},
      };
    case START_SENSINGS_RELOAD:
      return {
        ...state,
        sensingCharts: payload,
      };
    case FINISHED_SENSINGS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case SENSING_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    case READ_ALL_FARM_CLUSTERS:
      return {
        ...state,
        farmClusters: payload,
      };

    default:
      return state;
  }
}
