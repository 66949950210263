import {
  SET_TREE_DATA,
  READ_TREES,
  READ_ONE_TREE,
  READ_GEO_TREES,
  INC_GEO_TREES,
  CREATE_TREE,
  UPDATE_TREE,
  DELETE_TREE,
  CLEAR_TREE,
  START_TREES_RELOAD,
  FINISHED_TREES_RELOAD,
  TREE_ERROR,
  CLEAR_TREES,
  CLEAR_GEO_TREES,
  READ_TREES_WITH_FILTER,
  SEARCH_TREES,
  READ_ALL_INFECTED_TREE_DATA,
  READ_FIELD_INFECTED_TREE_DATA,
  READ_TREES_CREATION_STATUS,
} from "../../types/tree/trees";
const initialState = {
  trees: [],
  geo_trees: [],
  next: null,
  goe_trees_count: {},
  tree: {},
  geo_tree: {},
  loading: false,
  readable: false,
  totalPages: 0,
  page: 1,
  searchResults: [],
  errors: [],
  infectedData: {},
  infectedFieldData: [],
  treesCreationStatus: {},
};
export default function treesReducer(state = initialState, action) {
  const { type, payload } = action;
  // console.log({ payloadss: payload });
  switch (type) {
    case READ_ALL_INFECTED_TREE_DATA:
      return {
        ...state,
        infectedData: { ...payload },
      };
    case READ_FIELD_INFECTED_TREE_DATA:
      return {
        ...state,
        infectedFieldData: [...payload],
      };
    case SET_TREE_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_TREES:
      return {
        ...state,
        trees: payload.results,
        readable: true,
      };

    case SEARCH_TREES:
      return {
        ...state,
        searchResults: payload.results,
        readable: true,
      };
    case READ_TREES_WITH_FILTER:
      return {
        ...state,
        trees: payload,
        totalPages: payload?.total_pages,
      };
    case READ_GEO_TREES:
      return {
        ...state,
        geo_trees: payload.data,
        goe_trees_count: payload.count ? payload.count : {},
        // next: payload.next,
      };
    case INC_GEO_TREES:
      return {
        ...state,
        geo_trees: [...state.geo_trees, ...payload.results.data],
        goe_trees_count: payload.count,
        next: payload.next,
      };
    case READ_ONE_TREE:
      return {
        ...state,
        tree: payload,
      };
    case CREATE_TREE:
      return {
        ...state,
        trees: [payload, ...state.trees],
      };
    case UPDATE_TREE:
      return {
        ...state,
        trees: [
          ...state.trees.map((tree) =>
            tree.id === payload.tree.id ? payload.tree : tree
          ),
        ],
      };
    case DELETE_TREE:
      return {
        ...state,
        trees: [...state.trees.filter((tree) => tree.id !== payload.tree.id)],
      };
    case CLEAR_TREE:
      return {
        ...state,
        tree: {},
      };
    case CLEAR_TREES:
      return {
        ...state,
        trees: [],
      };
    case CLEAR_GEO_TREES:
      return {
        ...state,
        geo_trees: [],
      };

    case START_TREES_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_TREES_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case TREE_ERROR:
      return {
        ...state,
        // errors: [...state.errors, payload],
      };
    case READ_TREES_CREATION_STATUS:
      return {
        ...state,
        treesCreationStatus: payload,
      };

    default:
      return state;
  }
}
