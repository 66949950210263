import { useEffect, useState } from "react";
import { listsWithTree, listsWithoutTree } from "./sidebar-list.component";
import Image from "next/image";
import Link from "next/link";
import Head from "next/head";
import PlatfarmLogo from "assets/svg/Home.svg";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import MessageModal from "components/common/messageModal";
import NotAuthorizedAlert from "../components/common/notAuthorizedAlert";
import { ErrorBoundary } from "react-error-boundary";

import {
  responsiveAsideAlignment,
  responsiveHoverElement,
  responsiveActiveElement,
  responsiveDirection,
  responsiveFlexRow,
} from "./responsive-helper.component";
import Alerts from "./alerts.component";
import { useSwrGet } from "@/utils/use-swr-get";
import { authServices } from "services/auth.services";
import HandleError from "components/common/ErrorBoundary/index";
import { slackblocks } from "services/slackMessage";
const { SlackOAuthClient } = require("messaging-api-slack");

type Props = {
  children?: any;
};

const Layout = ({ children }: Props) => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const { data: messageData, isLoading: messageIsLoaing } = useSwrGet(
    `/message/all`,
    process.env.NEXT_PUBLIC_BASE_URL +
      `organizations/message/${authServices?.userValue?.org_id ?? ""}/`
  );
  useEffect(() => {
    messageData?.status === true
      ? setShowMessageModal(true)
      : setShowMessageModal(false);
  }, [messageData]);
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const logError = async (error: Error, info: { componentStack: string }) => {
    console.log("Error", error);
    /*   client.callMethod("chat.postMessage", {
      channel: "webcrashes",
      blocks: slackblocks(error, router, authServices),
      text: "New Crash Detected",
      replace_original: "true",
    }); */
  };
  const getFarmingType = () => {
    if (authServices?.userValue?.farming_type?.includes?.("tree")) {
      return listsWithTree;
    } else {
      return listsWithoutTree;
    }
  };
  const client = new SlackOAuthClient({
    accessToken: process.env.NEXT_PUBLIC_SLACK_BOT_TOKEN,
  });

  function capitalizeWords(inputString) {
    if (!inputString) {
      return "";
    }

    // Split the string into an array of words
    const words = inputString.split(" ");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map((word) => {
      if (word.length === 0) {
        return word;
      }
      const firstLetter = word[0].toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });

    // Join the capitalized words back into a string
    const result = capitalizedWords.join(" ");

    return result;
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
      </Head>

      <div
        className={` flex ${
          i18n.language === "en" ? "font-baloo" : "font-Almarai"
        }
     ${responsiveFlexRow(i18n.language)} 
      h-screen overflow-hidden   `}
      >
        <aside className="fixed flex flex-col items-center h-screen py-5 overflow-x-visible overflow-y-auto select-none w-[4.3rem] sm:w-20 xl:w-24 hideScrolBar bg-main">
          <div
            onClick={() => router.push("/farms")}
            className={`mb-3 transform transition cursor-pointer   h-full w-full flex justify-center   
           `}
          >
            <div className="xl:w-[45.35px] xl:h-[45.35px] w-[42.35px] h-[42.35px] relative">
              <Image
                layout="fill"
                priority={true}
                quality={50}
                /* =======> */ src={PlatfarmLogo /* Next50Logo */}
                alt="icon"
              />
            </div>
          </div>

          {getFarmingType?.()?.map?.((list: any, i: number) => {
            return list.path ? (
              <Link href={list.path} passHref key={i + Math.random()}>
                <a className="w-full rounded bg-sideBarHover">
                  <div
                    title={capitalizeWords(t(list.title))}
                    className={`flex flex-col  w-full items-center justify-center mb-5 cursor-pointer transform transition
                ${responsiveHoverElement(i18n.language)}
                ${responsiveActiveElement(list.path, i18n.language, router)}
                 duration-200 bg-main h-full`}
                  >
                    <div className="sm:w-[25px] w-[20px] h-[20px] sm:h-[25px] relative">
                      <Image
                        priority={true}
                        quality={50}
                        src={list.imgSrc}
                        alt={list.title}
                      />
                    </div>
                    <p className="py-1 text-[9px] md:text-[10px] w-[75px]  lg:w-full font-normal text-center text-white uppercase ">
                      {t(list.title)}
                    </p>
                  </div>
                </a>
              </Link>
            ) : (
              <div
                key={i + Math.random()}
                onClick={list.action}
                className="w-full rounded bg-sideBarHover"
              >
                <div
                  title={capitalizeWords(t(list.title))}
                  className={`flex flex-col  w-full items-center justify-center mb-5 cursor-pointer transform transition
                ${responsiveHoverElement(i18n.language)}
                ${responsiveActiveElement(list.path, i18n.language, router)}
                 duration-200 bg-main h-full`}
                >
                  <div className="w-[25px] h-[25px] relative overflow-hidden">
                    <Image
                      priority={true}
                      quality={50}
                      src={list.imgSrc}
                      alt={list.title}
                      className="object-cover"
                      layout="fill"
                    />
                  </div>
                  <p className="py-1 text-[12px] font-normal text-center text-white uppercase ">
                    {t(list.title)}
                  </p>
                </div>
              </div>
            );
          })}
        </aside>
        <main
          title=""
          style={{
            padding: 0,
            width: "100%",
            height: router.pathname.includes("navigation")
              ? "fit-content"
              : "auto",
            fontFamily: i18n.language === "en" ? "Baloo-regular" : "Almarai",
          }}
          dir={responsiveDirection(i18n.language)}
          className={`flex items-start ${responsiveAsideAlignment(
            i18n.language
          )} bg-[#F5F5F5] overflow-y-auto relative`}
        >
          <Alerts />

          <ErrorBoundary FallbackComponent={HandleError} onError={logError}>
            {children}
          </ErrorBoundary>
        </main>
      </div>

      {showMessageModal && (
        <MessageModal
          data={messageData}
          showModel={showMessageModal}
          setShowModel={setShowMessageModal}
        />
      )}
      <NotAuthorizedAlert />
    </>
  );
};

export default Layout;
