import {
  SET_TREE_TAB,
  TOGGLE_DETAILS,
  SET_CURRENT_TREE_TAB,
  TABLE_TAPS,
  TOGGLE_WEATHER,
  TOGGLE_LAYER,
  ACTIVE_NAVIGATE,
  DEACTIVE_NAVIGATE,
  IRRIGATION_CHART,
  TOGGLE_SUB_DETAILS,
  TOGGLE_SPRAY,
  TOGGLE_ADD_CLUSTER,
} from "../types/nav";
import Cookies from "js-cookie";
const initialState = {
  tree_tap: 0,
  navigate: false,
  add_cluster: false,
  details: {
    category: "",
    open: false,
    data: {},
  },
  subDetails: {
    category: "",
    open: false,
    data: {},
  },
  tables_taps: 0,
  table_tap_data: [],
  show_weather: false,
  show_spray: false,
  showIrrigationHold: false,
  show_notification: false,
  layer: Cookies.get("mapLayer") || "street",
  irrigation_chart: [],
  HoldfieldId: "",
  refreshHoldIrrigationData: false,
  holdIrrigationLoadng: false,
  show_calculate_irrigation: false,
  pivot_id: "",
  show__tree_calculate_irrigation: false,
  show_limit_exceeded_alert: false,
  show_location_pinpoint: false,
  map_search: false,
  latlng: [],
  drawOnMap: false,
  drawType: "polygon",
  show_cluster_pop_up: false,
  show_add_field_or_crop_field: false,
  drawCordinates: {},
  polygonArea: 0,
  notification_refresh_map: false,
  show_ask_platfarm: false,
  showCompare: false,
  cropFieldIdForCompare: {},
  current_Date: "",
  legend: "All",
};
export default function navigationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TREE_TAB:
      return {
        ...state,
        tree_tap: payload,
      };
    case TOGGLE_DETAILS:
      return {
        ...state,
        details: {
          category: payload.category,
          open: payload.open,
          data: payload.data,
        },
      };
    case TOGGLE_SUB_DETAILS:
      return {
        ...state,
        subDetails: {
          category: payload.category,
          open: payload.open,
          data: payload.data,
        },
      };
    case SET_CURRENT_TREE_TAB:
      return {
        ...state,
        tree_tap: payload,
      };
    case IRRIGATION_CHART:
      return {
        ...state,
        irrigation_chart: payload,
      };
    case TOGGLE_WEATHER:
      return {
        ...state,
        show_weather: !state.show_weather,
      };

    case TOGGLE_LAYER:
      return {
        ...state,
        layer: payload,
      };
    case ACTIVE_NAVIGATE:
      return {
        ...state,
        navigate: true,
      };
    case DEACTIVE_NAVIGATE:
      return {
        ...state,
        navigate: false,
      };
    case TABLE_TAPS:
      return {
        ...state,
        [payload.key]: payload.value,
      };

    case TOGGLE_ADD_CLUSTER:
      return {
        ...state,
        add_cluster: payload,
      };
    default:
      return state;
  }
}
