import {
  SET_FIELD_DATA,
  READ_FIELDS,
  READ_GEO_FIELDS,
  READ_ONE_FIELD,
  CREATE_FIELD,
  UPDATE_FIELD,
  DELETE_FIELD,
  CLEAR_FIELD,
  START_FIELDS_RELOAD,
  FINISHED_FIELDS_RELOAD,
  FIELD_ERROR,
  CLEAR_GEO_FIELD,
  CLEAR_IRRIGATION_FIELDS,
  READ_IRRIGATION_FIELDS,
  READ_WATER_TREE_CHART,
  READ_IRR_LAYERS_TREE_FIELDS,
  CLEAR_WATER_TREE_CHART,
  READ_ONE_FIELD_DETAILS,
  READ_TREE_FIELDS,
  READ_IRRIGATION_FAULTY_DRIPS,
  READ_LOGS,
  READ_ADVANCED_IRRIGATION_FIELD,
} from "../../types/tree/fields";

const initialState = {
  fields: [],
  geo_fields: [],
  irrigation_fields: [],
  faulty_drips: [],
  field: {},
  geo_field: {},
  form_field: {},
  one_field_details: {},
  loading: false,
  readable: false,
  errors: [],
  tree_water_chart_log: [],
  irrigation_layers_tree_fields: [],
  logs: [],
  advanced_irrigation_status: [],
  faulty_drips_counter: {},
};
export default function treeFieldsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_FIELD_DATA:
      return {
        ...state,
        [payload.key]: payload.value,
      };
    case READ_FIELDS:
      return {
        ...state,
        fields: payload.results,
        readable: true,
      };
    case READ_ONE_FIELD:
      return {
        ...state,
        field: payload,
      };
    case READ_ONE_FIELD_DETAILS:
      return {
        ...state,
        one_field_details: payload,
      };
    case READ_GEO_FIELDS:
      return {
        ...state,
        geo_fields: payload,
      };
    case READ_IRRIGATION_FIELDS:
      return {
        ...state,
        irrigation_fields: payload,
      };
    case CREATE_FIELD:
      return {
        ...state,
        fields: [payload, ...state.fields],
        field: payload,
      };
    case UPDATE_FIELD:
      return {
        ...state,
        fields: [
          ...state.fields.map((field) =>
            field.id === payload.field.id ? payload.field : field
          ),
        ],
      };
    case READ_IRR_LAYERS_TREE_FIELDS:
      return {
        ...state,
        irrigation_layers_tree_fields: payload,
      };
    case DELETE_FIELD:
      return {
        ...state,
        fields: [
          ...state.fields.filter((field) => field.id !== payload.field.id),
        ],
      };
    case CLEAR_IRRIGATION_FIELDS:
      return {
        ...state,
        irrigation_fields: [],
      };
    case CLEAR_FIELD:
      return {
        ...state,
        field: {},
      };
    case CLEAR_GEO_FIELD:
      return {
        ...state,
        geo_field: {},
      };
    case START_FIELDS_RELOAD:
      return {
        ...state,
        loading: true,
      };
    case FINISHED_FIELDS_RELOAD:
      return {
        ...state,
        loading: false,
      };
    case FIELD_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    case READ_WATER_TREE_CHART:
      return {
        ...state,
        tree_water_chart_log: payload,
      };
    case CLEAR_WATER_TREE_CHART:
      return {
        ...state,
        tree_water_chart_log: [],
      };
    case READ_LOGS:
      return {
        ...state,
        logs: payload,
      };
    case READ_IRRIGATION_FAULTY_DRIPS:
      return {
        ...state,
        faulty_drips: payload.results.data,
        faulty_drips_counter: payload.results.count,
      };
    case READ_ADVANCED_IRRIGATION_FIELD:
      return {
        ...state,
        advanced_irrigation_status: payload,
      };
    default:
      return state;
  }
}
